<template>
  <div>
    <editor
      v-bind="$attrs"
      v-on="$listeners"
      :mentions="macroIntellisense"
      @initialized="onInitiailized"
      @customize-modules="customizeQuillModules"
      :name="name"
      ref="editor"
      :value="value"
    />
    <div
      class="spell-check-box"
      ref="spellCheckBox"
      v-if="selectedWordToCheck"
      v-bind="spellCheckBoxPosition"
    >
      <SpellCheckBox
        :wordToCheck="wordToCheck"
        @close="closeSpellCheck"
        @ignore="ignoreWord"
        @replace="handleReplaceWord"
        @addWord="handleAddWord"
        :isForcedUpperCase="isForcedUpperCase"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import MacroModule, { macroStore } from "@/modules/Quill/MacroIntellisense.js";
import Editor from "./Editor.vue";
import Macro from "@/modules/Quill/Macro";
import Quill from "devextreme-quill";
import { getDefaultStyles } from "@/modules/getDefaultStylesByField";
import SpellCheckBox from "../SpellCheckBox.vue";
import spellChecker from "@/mixins/spellChecker";

Quill.register({ "modules/macro": Macro });

export default {
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: String
    }
  },
  components: {
    Editor,
    SpellCheckBox
  },
  mixins: [spellChecker],
  data: () => ({
    component: {},
    _editor: null
  }),
  methods: {
    onInitiailized({ component }) {
      component.register({ "modules/mentions": MacroModule }, true);
      this.component = component;
    },
    focus() {
      if (this.$refs?.editor?.focus) {
        this.$refs.editor.focus();
      }
    },
    expand() {
      if (this.$refs?.editor?.expand) {
        this.$refs.editor.expand();
      }
    },
    collapse() {
      if (this.$refs?.editor) {
        this.$refs.editor.collapse();
      }
    },
    customizeQuillModules(config) {
      config.macro = {
        name: this.name,
        styles: this.styles,
        initialized: true,
        generalOnly: true
      };
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      applicationSettings: state => state.applicationSettings,
      currentLab: state => state.currentLab,
      MacroSearchWithPeriod: state => state.labSettings.MacroSearchWithPeriod,
      currentSpecimen: state => state.accessionStore.currentSpecimen,
      labSettings: state => state.labSettings,
      enableSpellchecker: state => state.applicationSettings.enableSpellchecker
    }),
    ...mapGetters(["permissions"]),
    webSpellCheckInstance() {
      if (this.$refs.editor) {
        return this.$refs.editor.webSpellCheckInstance;
      }
      return null;
    },
    styles() {
      return getDefaultStyles(this.name, true);
    },
    macroIntellisense() {
      if (this.applicationSettings.macroAssist) {
        return [
          {
            displayExpr: "displayName",
            searchExpr: "displayName",
            valueExpr: "displayName",
            dataSource: macroStore(),
            marker: ".",
            minSearchLength: 2
          },
          {
            displayExpr: "displayName",
            searchExpr: "displayName",
            valueExpr: "displayName",
            dataSource: macroStore(),
            marker: "\\",
            minSearchLength: 2
          },
          {
            dataSource: ["C", "D", "M", "S"],
            marker: ";",
            minSearchLength: 0,
            isExtension: true
          }
        ];
      }
      return [];
    }
  }
};
</script>
<style lang="scss" scoped>
.toolbar {
  width: 100%;
}
.title {
  text-transform: capitalize;
  font-size: 1.2rem;
}
.is-invalid {
  border-color: #dc3545 !important;
}
.is-valid {
  border-color: #28a745 !important;
}

::v-deep .dx-icon,
::v-deep .dx-dropdowneditor-icon {
  &::before {
    color: #333 !important;
  }
}
.spell-check-box {
  position: absolute;
  top: 500px;
  left: 600px;
}
</style>
